import colors from 'vuetify/lib/util/colors';

const tailwindColors = {
    transparent: {
        DEFAULT: 'transparent',
    },
    white: {
        DEFAULT: '#ffffff',
    },
    black: {
        DEFAULT: '#000000',
    },
    primary: {
        DEFAULT: '#5d336b',  // Your primary color
        light: '#a06ab4',     // Lighter shade for primary
        dark: '#3b2244',      // Darker shade for primary
    },
    secondary: {
        DEFAULT: '#a06ab4',   // Your secondary color
        light: '#e845ae',     // Accent or lighter secondary color
        dark: '#5d336b',      // Darker secondary shade
    },
    success: {
        DEFAULT: '#2e7d32',
        light: '#4caf50',
        dark: '#1b5e20',
    },
    info: {
        DEFAULT: '#eba408',   // Your info color
        light: '#ffb84d',     // Light shade of warning
        dark: '#c28507',      // Darker info shade
    },
    accent: {
        DEFAULT: '#e845ae',   // Accent color (could also be used for a special category like error or success)
    },
    error: {
        DEFAULT: '#d32f2f',
        light: '#ef5350',
        dark: '#c62828',
    },
    warning: {
        DEFAULT: '#eba408',   // Use this for warning as well, since it fits a bright, alert color
        light: '#f9b208',     // Light shade of warning
        dark: '#d69605',      // Dark shade of warning
    },
    gray: {
        DEFAULT: '#9e9e9e',
        light: '#bdbdbd',
        dark: '#616161',
    },
};

function renameKeys(obj, newKeys) {
    const keyValues = Object.keys(obj).map((key) => {
        const newKey = newKeys[key] || key;
        return { [newKey]: obj[key] };
    });
    return Object.assign({}, ...keyValues);
}

let tailwindMaterialColorPack = colors;

const tailwindMap = {
    lighten5: '100',
    lighten4: '200',
    lighten3: '300',
    lighten2: '400',
    lighten1: '500',
    darken1: '600',
    darken2: '700',
    darken3: '800',
    darken4: '900',
    accent1: 'accent-100',
    accent2: 'accent-200',
    accent3: 'accent-300',
    accent4: 'accent-400',
    base: 'DEFAULT',
};

Object.keys(tailwindMaterialColorPack).forEach((color) => {
    tailwindMaterialColorPack[color] = renameKeys(tailwindMaterialColorPack[color], tailwindMap);
    tailwindMaterialColorPack[color].base = tailwindMaterialColorPack[color].DEFAULT;
});

// Merge the two objects (Material and Tailwind colors)
tailwindMaterialColorPack = { ...tailwindMaterialColorPack, ...tailwindColors };

export const colorConfig = tailwindMaterialColorPack;
