import { ref } from 'vue';
import { defineStore, acceptHMRUpdate } from 'pinia';
import axios from 'axios';

export const useKaleadicalStore = defineStore('kaleadical', () => {
    const current = ref(null);
    const all = ref(null);

    const reset = () => {
        current.value = null;
        all.value = null;
    };

    const actions = {
        search: (params) => axios.get('/api/v1/kaleadical', { params }),
        getKaleadical: (params) => axios.get(`/api/v1/kaleadical/${params.hash}`),
        storeKaleadical: (params) => axios.post('/api/v1/kaleadical', params),
        updateKaleadical: (params) => axios.patch(`/api/v1/kaleadical/${params.hash}`, params),
        destroyKaleadical: (params) => axios.delete(`/api/v1/kaleadical/${params.hash}`),
        updateTaskCounters: (params) => axios.patch(`/api/v1/kaleadical/${params.hash}/tasks`, params.tasks),
        saveState: (params) => axios.post(`/api/v1/kaleadical/${params.hash}/state`, params),
    };

    return {
        current,
        all,
        reset,
        ...actions,
    };
});

// HMR
if (import.meta.hot) {
    import.meta.hot.accept(acceptHMRUpdate(useKaleadicalStore, import.meta.hot));
}
