<script setup>
import { storeToRefs } from 'pinia';
import { useKaleadicalStore } from '@/stores/kaleadical';
import { router } from '@inertiajs/vue3';
const kaleadicalStore = useKaleadicalStore();
const { reset } = kaleadicalStore;
const { all } = storeToRefs(kaleadicalStore);

const view = (kaleadical) => {
    router.visit(`/kaleadical/${kaleadical.hash}/view`, { 
        onBefore: () => reset(), // Reset the store before navigating to the view page
    });
};
</script>

<template>
<!-- <div
    class="grid grid-cols-2 p-4 gap-4 rounded border shadow-lg w-1/2 mb-8 bg-white">
    <div class="col-span-full uppercase font-bold">
        <article
            v-for="kaleadical in all"
            :key="kaleadical.id"
            class="grid gap-4 cursor-pointer my-4 border hover:bg-gray-light"
            @click="view(kaleadical)">
            <hgroup>
                <h2 class="text-xl font-extrabold">{{ kaleadical.title }}</h2>
                <p>{{ kaleadical.description }}</p>
            </hgroup>
            <p>Number of tasks: {{ kaleadical.tasks?.length }}</p>
        </article>
    </div>
</div> -->
<v-container>
    <v-row>
        <v-col
            v-for="kaleadical in all"
            :key="kaleadical.id"
            cols="12"
            md="6"
            lg="4">
            <v-card class="cursor-pointer" @click="view(kaleadical)">
                <v-card-title class="headline">{{ kaleadical.title }}</v-card-title>
                <v-card-subtitle>{{ kaleadical.description }}</v-card-subtitle>
                <v-card-text>
                    <div>Tasks: {{ kaleadical.tasks?.length }}</div>
                </v-card-text>
            </v-card>
        </v-col>
    </v-row>
</v-container>
</template>
