<template>
<MainContentLayout>
    <Head title="404 - Page Not Found" />

    <template #breadcrumb>
        <BreadCrumb />
    </template>
    <!-- eslint-disable-next-line @intlify/vue-i18n/no-raw-text -->
    <p>404 Page Not Found</p>
</MainContentLayout>
</template>
