// const demo = {
//     name: 'Kaleadical/Demo',
//     path: '/kaleadical/demo',
//     icon: 'mdi-cube-outline',
//     label: 'Kaleadical Demo'
// };

const create = {
    name: 'Kaleadical/Create',
    path: '/kaleadical/create',
    icon: 'mdi-plus-box',
    label: 'Create Kaleadical'
};

const viewAll = {
    name: 'Kaleadical/ViewAll',
    path: '/kaleadical',
    icon: 'mdi-eye-outline',
    label: 'View All Kaleadicals'
};

const users = {
    name: 'Users/UsersPage',
    path: '/users',
    icon: 'mdi-account-group',
    label: 'Users'
};

const dashboard = {
    name: 'Dashboard/DashboardPage',
    path: '/',
    icon: 'mdi-view-dashboard',
    label: 'Dashboard'
};

const profile = {
    name: 'Dashboard/UserProfilePage',
    path: '/profile',
    icon: 'mdi-account',
    label: 'Profile'
};

const horizon = {
    name: 'Horizon',
    path: '/horizon',
    icon: 'mdi-sun-angle',
    label: 'Horizon',
    private: true,
    privateLabel: 'SA'
};

const pulse = {
    name: 'Pulse',
    path: '/pulse',
    icon: 'mdi-pulse',
    label: 'Pulse',
    private: true,
    privateLabel: 'SA'
};

const telescope = {
    name: 'Telescope',
    path: '/telescope',
    icon: 'mdi-telescope',
    label: 'Telescope',
    private: true,
    privateLabel: 'SA'
};

export const superAdmin = _ => {
    return [
        create,
        viewAll,
        dashboard,
        users
    ];
};

export const admin = _ => {
    return [
        create,
        viewAll,
        dashboard,
        users
    ];
};

// Regular User navigation
export const user = _ => {
    return [
        // demo,
        create,
        viewAll,
        dashboard,
    ];
};

// Bottom navigation for all users
export const bottomNavRoutes = _ => {
    return [
        profile,
        horizon,
        pulse,
        telescope,
    ];
};
