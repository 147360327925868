<script setup>
import { ref, inject } from 'vue';
import { useUserStore } from '@/stores/user';
import { storeToRefs } from 'pinia';

const { search } = useUserStore();
const { all } = storeToRefs(useUserStore());

const params = ref({
    term: '',
    status: null,
    page: 1,
    sort_by: null,
    per_page: 10,
    role: null,
});

const searchUser = () => {
    search({
        ...params.value,
    });
};

const modals = inject('modals');

const clearSearch = () => {
    params.value.term = '';
    searchUser();
};

const tableUpdate = ({ page, sortBy, itemsPerPage }) => {
    Object.assign(params.value, { page, sort_by: sortBy, per_page: itemsPerPage });
    searchUser();
};

const openModal = (modal, mode = 'Create', user = null) => {
    modals.show(modal, {
        props: user ? {
            user: user,
            mode: mode
        } : {
            mode: mode,
        }
    }).then(() => {
        searchUser();
    });
};

import { useOptionsStore } from '@/stores/options';
const optionsStore = useOptionsStore();
const { roleOptions } = storeToRefs(optionsStore);

</script>

<template>
<MainContentLayout>
    <template #breadcrumb><BreadCrumb /></template>
    <template #title>{{ $t('label.user_management') }}</template>
    <template #actions>
        <StyledButton @click="openModal('UsersModal')"><FontAwesomeIcon icon="plus" class="mr-1" />
            {{  $t('label.new_user') }}
        </StyledButton>
    </template>
    <div class="grid lg:grid-cols-3 md:gap-4 md:mb-4">
        <StyledSearch
            v-model="params.term"
            v-debounce:500ms="searchUser"
            name="search"
            :label="$t('label.search')"
            :debounce-events="['input']"
            @click:clear="clearSearch" />
        <StyledDropdown
            v-model="params.status"
            clearable
            :placeholder="$t('label.status')"
            :options="[
                { label: $t('label.active'), value: true },
                { label: $t('label.inactive'), value: false },
            ]"
            name="status"
            @input="searchUser" />
        <StyledDropdown
            v-model="params.role"
            clearable
            :placeholder="$t('label.role')"
            :options="roleOptions"
            name="role"
            @input="searchUser" />
    </div>
    <DataTable
        v-if="all"
        :data="all"
        :headers="[
            { title: $t('label.name'), key: 'full_name', value: item => `${item.first_name} ${item.last_name}`, sortable: false },
            { title: $t('label.email'), key: 'email', value: item => item.email, sortable: false },
            { title: $t('label.type'), key: 'type', value: item => item.roles.length ? item.roles[0].display_name : '', sortable: false},
            { title: $t('label.status'), key: 'status', value: item => item.is_active ? $t('label.active') : $t('label.inactive'), sortable: false },
        ]"
        :actions="[
            { title: 'Edit', icon: 'user-edit', action: (item) => openModal('UsersModal', 'Edit', item) },
        ]"
        @table-update="tableUpdate" />
</MainContentLayout>
</template>
