import { defineStore } from 'pinia';

export const useOptionsStore = defineStore('options', {
    state: () => ({
        roles: [],
        statuses: [],
        provinces: [],
        twoFactorMethods: [],
        securityQuestionTemplates: [],
        securityCheckpoint: false,
        shapes: [],
    }),
    actions: {
        reset() {
            this.$reset();
        },
    },
    getters: {
        roleOptions: (state) => {
            return state.roles;
        },

        provinceOptions: (state) => {
            return state.provinces.map((province) => ({
                label: province.name,
                value: province.id,
            }));
        }
    }
});

// HMR
if (import.meta.hot) {
    import.meta.hot.accept(useOptionsStore.acceptHMRUpdate);
}
