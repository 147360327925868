<!-- eslint-disable no-constant-condition -->
<script setup>
import { P5 } from 'p5vue';
import { ref } from 'vue';

import { useKaleadicalStore } from '@/stores/kaleadical';
import { storeToRefs } from 'pinia';
const kaleadicalStore = useKaleadicalStore();
const { current } = storeToRefs(kaleadicalStore);

// Define emits
const emit = defineEmits(['save-state', 'load-state']);
let saveRef = ref(null);
let loadRef = ref(null);

// External refs
let addRandomTaskRef = ref(null);
let addTaskRef = ref(null);
let resetCanvasViewportRef = ref(null); 
let zoomInRef = ref(null);
let zoomOutRef = ref(null);
let addMultipleTasksRef = ref(null);

let tasks = ref(
    current.value.tasks
);

let zoomLevel = 1;
const zoomFactor = 1.1;

let frameRateRef = ref(120);
let setFramerateRef = ref(null);

const selectedTask = ref(null);
const addSelectedTask = () => {
    if (!selectedTask.value) {
        return;
    }

    addTaskRef.value(selectedTask.value);
};

let toggleContinuousModeRef = ref(null);
let placedShapesRef = ref([]);
let taskLayersRef = ref({});
let globalNextLayerRef = ref(0);

const sketch = (p5) => {
    /**************************************************************
     *      State
     **************************************************************/

    const globalDenominator = 6;

    let state = {
        placedShapes: [],
        taskLayers: {},
        globalNextLayer: 0,
    };
    
    if (current?.value?.state) {
        state = JSON.parse(current.value.state);
    }

    let continuousMode = false; // Track whether continuous mode for adding shapes is enabled

    // Canvas center and shape size
    const width = 500;
    const height = 600;

    // eslint-disable-next-line no-unused-vars
    let canvas = null;
    let centerX = width / 2;
    let centerY = height / 2;
    let shapeRadius = 15;

    // Animation State
    let shapePhases = {}; // Controls the pulsing behavior
    let rotationDirections = {}; // Store the rotation direction for each shape

    /**************************************************************
     *      P5 Setup
     **************************************************************/

    p5.setup = () => {
        canvas = p5.createCanvas(width, height);
        p5.frameRate(frameRateRef.value);
        drawTessellation();
        resetCanvasViewport();
    };

    /**************************************************************
     *      Logic
     **************************************************************/

    function toggleContinuousMode() {
        continuousMode = !continuousMode;
    }

    toggleContinuousModeRef.value = toggleContinuousMode;

    // Function to add a task's shape to the tessellation
    function addTask(taskName) {
        const task = tasks.value.find((t) => t.title === taskName);

        if (task) {
            // Initialize task's layer progress if it hasn't started yet
            if (!state.taskLayers[task.title]) {
                state.taskLayers[task.title] = {
                    index: 0,
                    layer: state.globalNextLayer,
                };
                state.globalNextLayer++;
            }

            // Update the refs to trigger reactivity
            taskLayersRef.value = {};
            taskLayersRef.value = state.taskLayers;

            placeShapeInTaskLayer(task);
            //p5.redraw();
        }
    }

    addTaskRef.value = addTask;

    function placeShapeInTaskLayer(task) {
        const taskLayerData = state.taskLayers[task.title];

        while (true) {
            const currentLayer = taskLayerData.layer;
            const layerRadius = shapeRadius * (currentLayer + 1) * 2;
            const shapesInLayer =
            globalDenominator + currentLayer * globalDenominator;
            const angleStep = p5.TWO_PI / shapesInLayer;

            // Check if the current layer is full and if so, move to the next layer
            if (taskLayerData.index >= shapesInLayer) {
                taskLayerData.layer = state.globalNextLayer;
                state.globalNextLayer++;
                globalNextLayerRef.value = state.globalNextLayer;
                taskLayerData.index = 0; // Reset the index to start the new layer
                continue; // Repeat the loop to work on the new layer
            }

            // Calculate shape's position
            const angle = angleStep * taskLayerData.index;
            const xPos = centerX + p5.cos(angle) * layerRadius;
            const yPos = centerY + p5.sin(angle) * layerRadius;

            // Place the shape if no overlap is detected
            if (!isPositionOccupied(xPos, yPos)) {
                state.placedShapes.push({
                    task: task.title,
                    shape: task.shape,
                    colour: task.colour,
                    x: xPos,
                    y: yPos,
                    initialAngle: p5.degrees(angle),
                    angle: p5.degrees(angle),
                    currentScale: 1,
                    layer: currentLayer,  // Store the layer the shape is in
                });

                placedShapesRef.value = [...state.placedShapes];

                // Move to the next position in the layer
                taskLayerData.index++;
                break; // Exit the loop once the shape is placed
            }
        }
    }

    // Check if a given position is already occupied by any shape
    function isPositionOccupied(x, y) {
        for (let shape of state.placedShapes) {
            let d = p5.dist(shape.x, shape.y, x, y);
            if (d < shapeRadius * 1.8) {
                return true;
            }
        }
        return false;
    }

    // Utility function to add a random task
    function addRandomTask() {
        const taskNames = tasks.value.map((task) => task.title);
        const randomTask =
            taskNames[Math.floor(Math.random() * taskNames.length)];
        addTask(randomTask);
    }

    // Expose the addRandomTask function to the parent component
    addRandomTaskRef.value = addRandomTask;

    function resetCenter() {
        centerX = p5.width / 2;
        centerY = p5.height / 2;

        // Re-position all shapes around the new center point
        state.placedShapes.forEach((shapeData) => {
            const layerRadius = shapeRadius * (shapeData.layer + 1) * 2; // Use each shape's own layer

            // Recalculate the angle using the shape's initial angle
            const angle = p5.radians(shapeData.initialAngle);

            // Update the shape's position relative to the new center
            shapeData.x = centerX + p5.cos(angle) * layerRadius;
            shapeData.y = centerY + p5.sin(angle) * layerRadius;
        });

        p5.redraw(); // Trigger a redraw to update the canvas
    }

    function resetCanvasViewport() {
        // get the width of container-div 
        const containerDiv = document.getElementById('container-div');
        const containerWidth = containerDiv.offsetWidth;

        p5.resizeCanvas(containerWidth, height);
        resetCenter(); // Adjust the center position of the shapes
        drawTessellation(); // Redraw the tessellation
    }

    resetCanvasViewportRef.value = resetCanvasViewport;

    /**************************************************************
     *      Save and Load
     **************************************************************/
    function save() {
        const stateSerialized = JSON.stringify(state);

        localStorage.setItem('kaleadicalState', stateSerialized);
        emit('save-state', stateSerialized);
    }

    function load() {
        state = JSON.parse(localStorage.getItem('kaleadicalState'));
    }

    // Assign the save and load functions to refs
    saveRef.value = save;
    loadRef.value = load;

    /**************************************************************
     *      Drawing
     **************************************************************/

    function drawTessellation() {
        p5.background('#1A1A1A'); // Dark background for contrast
    
        p5.push(); // Save the current transformation state

        // Translate the canvas to the center before applying the zoom
        p5.translate(centerX, centerY);
        p5.scale(zoomLevel);
        p5.translate(-centerX, -centerY);

        // Draw each placed shape
        for (let shapeData of state.placedShapes) {
            drawShape(shapeData);
        }
    
        p5.pop(); // Restore transformation state to avoid affecting UI
    }

    function drawShape(shapeData) {
        p5.fill(shapeData.colour);
        p5.noStroke();
        p5.push();
        p5.translate(shapeData.x, shapeData.y);

        // Apply the scaling effect
        const currentScale = shapeData.currentScale || 1; // Use currentScale or default to 1
        p5.scale(currentScale); // Apply scaling effect

        // Apply the individual rotation effect
        p5.rotate(p5.radians(shapeData.angle));

        switch (shapeData.shape) {
        case 'triangle':
            drawTriangle();
            break;
        case 'square':
            p5.rectMode(p5.CENTER);
            p5.rect(0, 0, shapeRadius * 2, shapeRadius * 2);
            break;
        case 'hexagon':
            drawPolygon(0, 0, shapeRadius, 6);
            break;
        case 'pentagon':
            drawPolygon(0, 0, shapeRadius, 5);
            break;
        case 'octagon':
            drawPolygon(0, 0, shapeRadius, 8);
            break;
        case 'diamond':
            drawDiamond();
            break;
        case 'parallelogram':
            drawParallelogram();
            break;
        case 'star':
            drawStar(5, shapeRadius, shapeRadius / 2);
            break;
        case 'trapezoid':
            drawTrapezoid();
            break;
        case 'cross':
            drawCross();
            break;
        default:
            console.error(`Unknown shape type: ${shapeData.shape}`);
        }
        p5.pop();
    }

    // Additional shape-drawing functions
    function drawTriangle() {
        const triHeight = (shapeRadius * p5.sqrt(3)) / 2;
        p5.triangle(
            0,
            -triHeight,
            -shapeRadius,
            triHeight / 2,
            shapeRadius,
            triHeight / 2,
        );
    }

    function drawPolygon(x, y, radius, sides) {
        p5.beginShape();
        for (let i = 0; i < sides; i++) {
            let angle = (p5.TWO_PI / sides) * i;
            let px = x + p5.cos(angle) * radius;
            let py = y + p5.sin(angle) * radius;
            p5.vertex(px, py);
        }
        p5.endShape(p5.CLOSE);
    }

    function drawDiamond() {
        p5.beginShape();
        p5.vertex(0, -shapeRadius);
        p5.vertex(shapeRadius / 2, 0);
        p5.vertex(0, shapeRadius);
        p5.vertex(-shapeRadius / 2, 0);
        p5.endShape(p5.CLOSE);
    }

    function drawParallelogram() {
        p5.beginShape();
        p5.vertex(-shapeRadius, shapeRadius / 2);
        p5.vertex(0, shapeRadius / 2);
        p5.vertex(shapeRadius, -shapeRadius / 2);
        p5.vertex(0, -shapeRadius / 2);
        p5.endShape(p5.CLOSE);
    }

    function drawStar(points, outerRadius, innerRadius) {
        let angle = p5.TWO_PI / points;
        p5.beginShape();
        for (let i = 0; i < p5.TWO_PI; i += angle) {
            let x1 = p5.cos(i) * outerRadius;
            let y1 = p5.sin(i) * outerRadius;
            p5.vertex(x1, y1);
            let x2 = p5.cos(i + angle / 2) * innerRadius;
            let y2 = p5.sin(i + angle / 2) * innerRadius;
            p5.vertex(x2, y2);
        }
        p5.endShape(p5.CLOSE);
    }

    function drawTrapezoid() {
        p5.beginShape();
        p5.vertex(-shapeRadius, shapeRadius / 2);
        p5.vertex(shapeRadius, shapeRadius / 2);
        p5.vertex(shapeRadius / 2, -shapeRadius / 2);
        p5.vertex(-shapeRadius / 2, -shapeRadius / 2);
        p5.endShape(p5.CLOSE);
    }

    function drawCross() {
        p5.rectMode(p5.CENTER);
        p5.rect(0, 0, shapeRadius * 1.5, shapeRadius / 2);
        p5.rect(0, 0, shapeRadius / 2, shapeRadius * 1.5);
    }

    /**************************************************************
     *      Animation
     **************************************************************/
    function animateLayers() {
        state.placedShapes.forEach((shapeData, index) => {
            // Initialize the phase and rotation direction if not already set
            if (shapePhases[index] === undefined) {
                shapePhases[index] = p5.random(p5.TWO_PI); // Start with a random phase
            }

            if (rotationDirections[index] === undefined) {
                rotationDirections[index] = index % 2 === 0 ? 1 : -1; // Alternate rotation direction: even index rotates clockwise, odd index counterclockwise
            }

            // Adjust the individual rotation of the shape
            const individualRotationSpeed = 0.01; // Adjust this value for the desired rotation speed
            shapeData.angle +=
                p5.degrees(individualRotationSpeed) * rotationDirections[index];

            // Update the phase to control the pulsing effect
            const pulseSpeed = 0.05; // Rate of the pulse
            shapePhases[index] += pulseSpeed; // Increment the phase

            // Calculate the scaling factor using a sine wave for the pulsing effect
            const maxScale = 1.2; // Maximum scale size
            const minScale = 0.8; // Minimum scale size
            const pulse = (p5.sin(shapePhases[index]) + 1) / 2; // Value oscillates smoothly between 0 and 1
            const currentScale = minScale + pulse * (maxScale - minScale); // Map pulse to scale range

            // Apply the scale transformation to the shape data
            shapeData.currentScale = currentScale; // Store the current scale in the shape data
        });
    }

    /**************************************************************
     *      P5 Draw Hook
     **************************************************************/
    p5.draw = () => {
        // Only add a shape if continuousMode is enabled
        if (continuousMode) {
            addRandomTask();
        }

        animateLayers(); // Apply scaling and rotation animation
        drawTessellation(); // Redraw the tessellation
    };

    // Hook for when the window width changes
    p5.windowResized = () => {
        resetCanvasViewport();
    };

    /**************************************************************
     *      Framerate
     **************************************************************/

    function setFramerate() {
        p5.frameRate(frameRateRef.value);
    }

    setFramerateRef.value = setFramerate;

    /**************************************************************
     *      Demo
     **************************************************************/
    
    function addMultipleTasks() {
        for (let i = 0; i < 500; i++) {
            addRandomTask();
        }
    }

    addMultipleTasksRef.value = addMultipleTasks;

    /**************************************************************
     *      Zooming
     **************************************************************/

    function zoomIn() {
        zoomLevel *= zoomFactor;
        applyZoom();
    }

    function zoomOut() {
        zoomLevel /= zoomFactor;
        applyZoom();
    }

    function applyZoom() {
        p5.redraw(); // Force a redraw after changing the zoom level
    }

    zoomInRef.value = zoomIn;
    zoomOutRef.value = zoomOut;

};
// Call the setup function with the emit reference
sketch(emit);
</script>

<template>
<!-- eslint-disable @intlify/vue-i18n/no-raw-text -->
<div id="container-div">
    <P5 :sketch="sketch" />
    <div
        class="grid grid-cols-2 p-4 gap-4 rounded border shadow-lg bg-white mt-4">
        <div class="col-span-full uppercase font-bold">Debugging</div>
        <v-btn @click="addRandomTaskRef">Add Random Task</v-btn>
        <v-btn
            @click="toggleContinuousModeRef">Toggle Continuous Mode</v-btn>
        <div class="col-span-full grid grid-cols-2 gap-2">
            <v-select
                v-model="selectedTask"
                :items="tasks"
                label="Select Task" />
            <v-btn @click="addSelectedTask">Add Selected Task</v-btn>
        </div>
        <v-btn @click="saveRef">save</v-btn>
        <v-btn @click="resetCanvasViewportRef">Reset Viewport</v-btn>

        <v-btn @click="zoomInRef">Zoom In</v-btn>
        <v-btn @click="zoomOutRef">Zoom Out</v-btn>

        <v-btn @click="addMultipleTasksRef">Add Multiple Tasks</v-btn>

        <!-- <div class="col-span-full grid grid-cols-2 gap-2">
            <v-text-field
                v-model="frameRateRef"
                label="Frame Rate"
                type="number" />
            <v-btn @click="setFramerateRef">Set Frame Rate</v-btn>
        </div> -->

    </div>

    <!-- <div class="flex flex-col gap-4 mb-4 border p-2">
        <div v-for="(shape, index) in placedShapesRef" :key="index">
            {{ shape }}
        </div>
    </div>

    <div class="p-4 border">
        {{ taskLayersRef }}
    </div>

    <div class="p-4 border">
        Global next layer: {{ globalNextLayerRef }}
    </div> -->
</div>
</template>
