<script setup>
import ModalWrapper from '@/modals/ModalWrapper';
import { ref } from 'vue';
import { useToast } from 'vue-toastification';
import { useUserStore } from '@/stores/user';
import { storeToRefs } from 'pinia';

const props = defineProps({
    user: {
        type: Object,
        default: () => ({
            role: null,
            first_name: '',
            last_name: '',
            username: '',
            email: '',
            phone_number: '',
        })
    },
    mode: {
        type: String,
        default: 'Create'
    }
});

const emit = defineEmits(['close-modal']);
const form = ref({ ...props.user });

const toast = useToast();

import { useOptionsStore } from '@/stores/options';
const optionsStore = useOptionsStore();
const { roleOptions } = storeToRefs(optionsStore);

const userStore = useUserStore();
const { storeUser, updateUser } = userStore;

if (props.user.hash && props.user.roles) {
    const matchedRole = roleOptions.value.find(role => role.id === props.user.roles[0].id);
    if (matchedRole) {
        form.value.role = matchedRole.name;
    }
}

const save = async () => {
    try {
        const action = props.mode === 'Create' ? storeUser : updateUser;
        const toastMessage = props.mode === 'Create' ? 'User Created' : 'User Updated';

        await action({
            ...form.value,
            hash: props.user.hash,
        });

        toast.success(toastMessage);
        emit('close-modal');
    } catch (error) {
        toast.error('An unexpected error occurred');
    }
};
</script>

<template>
<ModalWrapper>
    <template #header>{{ mode }} {{ $t('label.user') }}</template>
    <template #subheader>{{ $t('label.create_or_edit') }}</template>

    <form @submit.prevent="save">
        <!-- User Information Row -->
        <v-row dense>
            <v-col cols="12" md="6">
                <StyledInput
                    v-model="form.first_name"
                    name="first_name"
                    :placeholder="$t('label.first_name')"
                    :label="$t('label.first_name')" />
            </v-col>
            <v-col cols="12" md="6">
                <StyledInput
                    v-model="form.last_name"
                    name="last_name"
                    :placeholder="$t('label.last_name')"
                    :label="$t('label.last_name')" />
            </v-col>
        </v-row>

        <v-row dense>
            <v-col cols="12" md="6">
                <StyledInput
                    v-model="form.email"
                    name="email"
                    :placeholder="$t('label.email')"    
                    :label="$t('label.email')" />
            </v-col>
            <v-col cols="12" md="6">
                <StyledInput
                    v-model="form.username"
                    name="username"
                    :placeholder="$t('label.username')"    
                    :label="$t('label.username')" />
            </v-col>
        </v-row>

        <!-- Role Selection -->
        <v-row dense>
            <v-col cols="12" md="6">
                <StyledDropdown
                    v-model="form.role"
                    item-title="display_name"
                    item-value="name"
                    :options="roleOptions"
                    name="role"
                    :label="$t('label.role')" 
                    :placeholder="$t('label.select_role')" />
            </v-col>
        </v-row>

        <!-- Password Options -->
        <v-row dense>
            <v-col cols="12" md="6">
                <StyledRoundSwitch
                    v-model="form.send_password"
                    :label="$t('label.email_password_setup')"
                    inset
                    name="send_password"
                    @input="form.set_password = false" />
            </v-col>
            <v-col cols="12" md="6">
                <StyledRoundSwitch
                    v-model="form.set_password"
                    :label="mode === 'Edit' ? 'Reset Password' : 'Set Password'"
                    inset
                    name="set_password"
                    @input="form.send_password = false" />
            </v-col>
        </v-row>

        <!-- Password Setup (own row) -->
        <v-row v-if="form.set_password" dense>
            <v-col cols="12" md="6">
                <StyledPassword
                    v-model="form.password"
                    name="password"
                    :label="$t('label.password')" />
            </v-col>
            <v-col cols="12" md="6">
                <StyledPassword
                    v-model="form.password_confirmation"
                    name="password_confirmation"
                    :label="$t('label.confirm_password')" />
            </v-col>
        </v-row>
    </form>

    <template #footer>
        <StyledSaveButton modal @save="save" />
    </template>
</ModalWrapper>
</template>
