<script setup>
import { useKaleadicalStore } from '@/stores/kaleadical';
import { storeToRefs } from 'pinia';
import GenerateArt from '@/components/GenerateArt.vue';
const kaleadicalStore = useKaleadicalStore();
const { saveState } = kaleadicalStore;
const { current } = storeToRefs(kaleadicalStore);

const saveKaleadicalState = (state) => {
    const payload = {
        hash: current.value.hash,
        state: state
    };
    
    saveState(payload);
    // updateTaskCounters({ hash: current.value.hash, tasks: tasks.value });
};

const loadKaleadicalState = (_) => {
    // updateTaskCounters({ hash: current.value.hash, tasks: tasks.value });
};

// const generateArt = () => {
//     router.visit(`/kaleadical/${current.value.hash}/progress`, { 
//         onBefore: () => saveTaskState(), // Reset the store before navigating to the view page
//         onSuccess: () => router.visit(`/kaleadical/${current.value.hash}/progress`)
//     });
// };
</script>

<template>
<!-- eslint-disable @intlify/vue-i18n/no-raw-text -->
<div>
    <template v-if="current">
        <div v-if="current" class="flex items-center p-4 gap-4 rounded border shadow-lg mb-8 bg-white">
            <hgroup class="col-span-full uppercase font-bold">
                <h2 class="text-xl font-extrabold">{{ current.title }}</h2>
                <p>{{ current.description }}</p>
            </hgroup>
            <div class="space-y-4">
                <!-- <StyledButton class="ml-auto" :disabled="isLoading" @click="saveTaskState()">Save Changes</StyledButton> -->
                <!-- <StyledButton class="ml-auto" :disabled="isLoading" @click="saveTaskState()">Load</StyledButton> -->
            </div>
        </div>

        <GenerateArt
            @save-state="saveKaleadicalState"
            @load-state="loadKaleadicalState" />
    </template>
</div>
</template>
