<script setup>
import { ref } from 'vue';
import axios from 'axios';
import { useToast } from 'vue-toastification';
import { router } from '@inertiajs/vue3';

// Toast instance
const toast = useToast();

// Refs for goal, tasks, and task list
const title = ref('');
const goal = ref('');
const loading = ref(false);
const errorMessage = ref('');
const shapesWithIcons = {
    hexagon: 'mdi-hexagon-outline',
    pentagon: 'mdi-pentagon-outline',
    octagon: 'mdi-octagon-outline',
    diamond: 'mdi-diamond-outline',
    trapezoid: 'mdi-rhombus-outline',
    cross: 'mdi-cross-outline',
};

// The unified taskList ref that will store title and tasks
const taskList = ref({
    title: '',
    tasks: [],
});

// Function to generate tasks via AI
const generateTasks = async () => {
    loading.value = true;
    errorMessage.value = '';
    taskList.value.tasks = []; // Reset tasks before generating new ones

    try {
        const response = await axios.post('/api/v1/chatgpt/generate-tasks', {
            goal: goal.value,
        });

        if (response.data) {
            taskList.value.tasks = response.data.map(task => ({
                name: task.name,
                color: task.color,
                shape: task.shape,
            }));
            taskList.value.title = title.value; // Assign the title from the input
        } else {
            toast.info('No tasks found.');
        }
    } catch (error) {
        toast.error('Failed to generate tasks. Please try again later.');
    } finally {
        loading.value = false;
    }
};

// Modal related refs for manual task creation
const showModal = ref(false);
const newTask = ref({
    name: '',
    shape: '',
    color: '',
});

const shapes = Object.keys(shapesWithIcons);
const swatches = ['#FF0000', '#00FF00', '#0000FF', '#FFFF00', '#FFA500', '#800080'];

// Function to add a new task manually
const addTask = () => {
    taskList.value.tasks.push({
        name: newTask.value.name,
        color: newTask.value.color,
        shapeIcon: shapesWithIcons[newTask.value.shape] || '',
    });
    newTask.value = { name: '', shape: '', color: '' }; // Reset new task inputs
};

// Function to remove a task
const removeTask = (index) => {
    taskList.value.tasks.splice(index, 1);
};

// Function to save tasks manually
const saveTasks = () => {
    if (!taskList.value.title) {
        taskList.value.title = title.value; // Ensure the task list has a title
    }
    toast.success('Tasks saved successfully!');
    showModal.value = false;
};

// Function to send the task list to the backend
const submitTaskList = async () => {
    if (!taskList.value.title || taskList.value.tasks.length === 0) {
        toast.error('Please provide a title and add at least one task.');
        return;
    }

    // Prepare the payload with the correct key names
    const taskListToSubmit = {
        title: taskList.value.title,
        tasks: taskList.value.tasks.map(task => ({
            title: task.name,       // Change 'name' to 'title'
            shape: task.shape,      // Ensure 'shape' is included
            colour: task.color      // Change 'color' to 'colour' (British spelling)
        }))
    };

    try {
        await axios.post('/api/v1/kaleadical', taskListToSubmit);
        toast.success('Task list submitted successfully!');
    } catch (error) {
        toast.error('Failed to submit task list.');
        console.error('Submission error:', error);
    }

    router.visit('/kaleadical');// Redirect to the home page after submission
};

</script>
<template>
<v-container class="py-4" max-width="600">
    <v-card elevation="2">
        <v-card-title class="justify-center">
            <h4>Create Task List</h4>
        </v-card-title>
    
        <v-card-text>
            <!-- Input for entering task list title -->
            <v-text-field
                v-model="title"
                label="Enter the task list title"
                outlined
                dense
                class="mb-4" />
            <!-- Input for entering goal -->
            <v-text-field
                v-model="goal"
                label="Enter your goal and we will generate tasks for you"
                outlined
                dense
                class="mb-4" />
    
            <!-- Buttons -->
            <div class="d-flex justify-center mb-4">
                <v-btn
                    color="primary"
                    :loading="loading"
                    class="mr-2"
                    @click="generateTasks">
                    Generate Tasks
                </v-btn>
                <span class="mx-2">OR</span>
                <v-btn color="secondary" @click="showModal = true">
                    Add Tasks Manually
                </v-btn>
            </div>
    
            <!-- Display the generated tasks -->
            <div v-if="taskList.tasks.length > 0">
                <h3 class="font-bold mb-2">Tasks</h3>
                <v-list>
                    <v-slide-x-transition group>
                        <v-list-item
                            v-for="(task, index) in taskList.tasks"
                            :key="index"
                            class="mb-2 task-item">
                            <v-list-item-content>
                                <v-list-item-title>
                                    <v-icon :color="task.color" class="mr-2">{{ task.shapeIcon }}</v-icon>
                                    {{ task.name }}
                                </v-list-item-title>
                                <v-list-item-subtitle>
                                    <span :style="{ color: task.color }">{{ task.color }}</span>
                                </v-list-item-subtitle>
                            </v-list-item-content>
                            <v-list-item-action>
                                <v-btn icon @click="removeTask(index)">
                                    <v-icon color="red">mdi-delete</v-icon>
                                </v-btn>
                            </v-list-item-action>
                        </v-list-item>
                    </v-slide-x-transition>
                </v-list>
            </div>
    
            <!-- Submit Task List Button -->
            <v-btn color="success" @click="submitTaskList">Submit Task List</v-btn>
        </v-card-text>
    </v-card>
    
    <!-- Manual Task Creation Modal -->
    <v-dialog v-model="showModal" max-width="600">
        <v-card>
            <v-card-title>
                <span class="text-h6">Create Tasks Manually</span>
                <v-spacer />
                <v-btn icon @click="showModal = false">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-card-title>
    
            <v-card-text>
                <!-- Task List -->
                <v-list v-if="taskList.tasks.length">
                    <v-subheader>Task List</v-subheader>
                    <v-list-item
                        v-for="(task, index) in taskList.tasks"
                        :key="index"
                        class="task-item d-flex align-center">
                        <!-- Icon Avatar for Shape -->
                        <v-avatar :color="task.color" class="mr-2">
                            <v-icon>{{ task.shapeIcon }}</v-icon>
                        </v-avatar>
    
                        <!-- Task Name -->
                        <v-list-item-content class="mr-2">
                            <v-list-item-title class="mb-0">
                                {{ task.name }}
                            </v-list-item-title>
                        </v-list-item-content>
    
                        <!-- Task Color -->
                        <span :style="{ color: task.color }" class="mr-2">{{ task.color }}</span>
    
                        <!-- Delete Button -->
                        <v-list-item-action>
                            <v-btn icon @click="removeTask(index)">
                                <v-icon color="red">mdi-delete</v-icon>
                            </v-btn>
                        </v-list-item-action>
                    </v-list-item>
    
                    <!-- Divider between tasks -->
                    <v-divider v-if="index < taskList.tasks.length - 1" />
                </v-list>
    
                <!-- Add New Task -->
                <div class="mt-4">
                    <h4>Add New Task</h4>
                    <v-text-field
                        v-model="newTask.name"
                        label="Task Name"
                        outlined
                        dense
                        class="mb-2" />
                    <v-select
                        v-model="newTask.shape"
                        :items="shapes"
                        label="Shape"
                        outlined
                        dense
                        class="mb-2" />
                    <v-color-picker
                        v-model="newTask.color"
                        hide-mode-switch
                        :swatches="swatches"
                        flat
                        class="mb-4" />
                    <v-btn
                        color="primary"
                        :disabled="!newTask.name || !newTask.shape || !newTask.color"
                        @click="addTask">
                        Add Task
                    </v-btn>
                </div>
            </v-card-text>
    
            <v-card-actions>
                <v-spacer />
                <v-btn color="primary" @click="saveTasks">Save</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</v-container>
</template>
  
  <style scoped>
  .color-preview {
    width: 24px;
    height: 24px;
    border-radius: 50%;
  }
  
  .task-item {
    display: flex;
    align-items: center;
    gap: 16px;
  }
  
  .task-item .v-icon {
    font-size: 24px;
  }
  </style>
  
