<script setup>
import { ref, watch, nextTick, inject } from 'vue';
import { router } from '@inertiajs/vue3';
import { useKaleadicalStore } from '@/stores/kaleadical';
import { storeToRefs } from 'pinia';
import { useStringFormatter } from '@/composables/stringFormatter';
const { capitalizeFirstLetter } = useStringFormatter();
const kaleadicalStore = useKaleadicalStore();
const { updateTaskCounters } = kaleadicalStore;
const { current } = storeToRefs(kaleadicalStore);

const isLoading = inject('isLoading');

let tasks = ref([]);
let state = ref({});

// const increment = task => task.counter++;
// const decrement = task => task.counter > 0 && task.counter--;
// const counterTotal = computed(() => tasks.value.reduce((acc, task) => acc + task.counter, 0));
// const counterReset = () => tasks.value.forEach(task => task.counter = 0);

watch(current, async (kaleadical) => {
    await nextTick();
    tasks.value = kaleadical.tasks;
    const kaleadicalState = JSON.parse(kaleadical.state);
    if (kaleadical.state) {
        state.value = kaleadicalState;
    }
}, {
    deep: true
});

const saveTaskState = () => {
    updateTaskCounters({ hash: current.value.hash, tasks: tasks.value });
};

const generateArt = () => {
    router.visit(`/kaleadical/${current.value.hash}/progress`, { 
        onBefore: () => saveTaskState(), // Reset the store before navigating to the view page
        onSuccess: () => router.visit(`/kaleadical/${current.value.hash}/progress`)
    });
};
</script>

<template>
<!-- eslint-disable @intlify/vue-i18n/no-raw-text -->
<div>
    <template v-if="current">
        <v-container>
            <v-row>
                <v-col cols="12">
                    <v-card class="mb-8">
                        <v-card-title class="text-h5 font-weight-bold">{{ current.title }}</v-card-title>
                        <v-card-subtitle>{{ current.description }}</v-card-subtitle>
                    </v-card>
                </v-col>
            </v-row>

            <v-row class="d-flex justify-space-between align-center">
                <v-col cols="auto">
                    <v-btn :disabled="isLoading" color="primary" @click="generateArt">View Kaleadical</v-btn>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12">
                    <v-list>
                        <v-list-item-group>
                            <div class="text-2xl font-medium p-4">
                                Tasks
                            </div>
                            <v-list-item
                                v-for="task in tasks"
                                :key="task.id">
                                <v-list-item-content>
                                    <v-list-item-title class="text-h6 font-weight-bold">{{ task.title }}</v-list-item-title>
                                    <v-list-item-subtitle :style="{ color: task.colour }">
                                        {{ capitalizeFirstLetter(task.shape) }}
                                    </v-list-item-subtitle>
                                    <v-divider />
                                </v-list-item-content>
                            </v-list-item>
                        </v-list-item-group>
                    </v-list>
                </v-col>
            </v-row>
        </v-container>
    </template>
</div>
</template>
