<script setup>
import { toRaw, watch } from 'vue';
import { useUserStore } from '@/stores/user';
import { useForm } from 'laravel-precognition-vue';

const form = useForm('patch', '/api/v1/profile', {
    email: null,
    first_name: null,
    last_name: null,
    phone_number: null,
    password: null,
    password_confirmation: null,
    navigation: 'side',
});

const userStore = useUserStore();

watch (() => userStore.profile, () => {
    form.setData(toRaw({ ...userStore.profile }));
});

const cancel = () => {
    window.history.go(-1);
};

const save = () => {
    form.submit();
};
</script>

<template>
<MainContentLayout>
    <template #breadcrumb>
        <BreadCrumb />
    </template>
    <template #title>
        {{ $t('label.profile') }}
    </template>
    <template #actions>
        <StyledButton
            size="small"
            variant="outlined"
            @click="$inertia.visit('/profile/security-questions')">
            <FontAwesomeIcon
                icon="lock"
                class="mr-1" />
            {{ $t('label.change_security_questions_and_answers') }}
        </StyledButton>
    </template>
    <form class="grid grid-cols-1 sm:grid-cols-2 gap-4 w-full lg:w-1/2">
        <StyledInput
            v-model="form.first_name"
            :label="$t('label.first_name')"
            :placeholder="$t('label.first_name')"
            name="first_name"
            @change="form.validate('first_name')" />
        <StyledInput
            v-model="form.last_name"
            :label="$t('label.last_name')"
            :placeholder="$t('label.last_name')"
            name="last_name"
            @change="form.validate('last_name')" />
        <StyledInput
            v-model="form.email"
            :label="$t('label.email')"
            :placeholder="$t('label.email')"
            name="email"
            type="email"
            @change="form.validate('email')" />
        <StyledInput
            v-model="form.password"
            :label="$t('label.password')"
            :placeholder="$t('label.password')"
            name="password"
            autocomplete="new-password"
            type="password" />
        <StyledInput
            v-model="form.password_confirmation"
            :label="$t('label.password_confirmation')"
            :placeholder="$t('label.password_confirmation')"
            name="password_confirmation"
            type="password" />
        <StyledSaveButton @save="save" @cancel="cancel" />
    </form>
</MainContentLayout>
</template>
